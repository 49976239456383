// @ts-strict-ignore
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GuestDTO, SpaProductFamilyDTO, SpaUIService, UiSpaBookingDTO } from '@api';
import { finalize, take } from 'rxjs';
import {
  Column,
  PaginatedResponse,
  Pagination,
  Row,
  wrapElements,
} from '../../../../components/spa-table/spa-table.component';
import { FormatDatePipe } from '@pipe';
import { ProductUtils } from '@utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StoreService } from '@state';
import { FormatPricePipe } from '../../../../pipes/format-price.pipe';

@UntilDestroy()
@Component({
  selector: 'spa-existing-bookings',
  templateUrl: './existing-bookings.component.html',
  styleUrls: ['./existing-bookings.component.scss'],
  providers: [FormatDatePipe, FormatPricePipe],
})
export class ExistingBookingsComponent {
  // TODO: fix eslint error
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('guestExistingBookings') set setGuest(guest: GuestDTO) {
    if (guest) {
      this.guest = guest;
      this.loadGuestBookings();
    }
  }

  guest: GuestDTO;

  @Output() closeExistingBookings = new EventEmitter<string | undefined>();

  bookings: PaginatedResponse<Row<UiSpaBookingDTO>>;
  loading = false;
  pageSize = 7;

  products: SpaProductFamilyDTO[];

  tableColumns: Column<UiSpaBookingDTO>[] = [
    {
      key: 'ticket-product',
      value: ({ element }) => (element.event?.name ? element.event.name : element.product.name),
    },
    {
      key: 'date',
      translationKey: 'column.booking-confirm-table.date-time',
      value: ({ element }) => {
        let tickets = '';
        for (let i = 0; i < element.tickets.length; i++) {
          tickets = tickets.concat(
            this.formatDatePipe.transform(element.tickets[i].fromDate, 'DD.MM.YYYY, HH:mm') +
              ' - ' +
              this.formatDatePipe.transform(element.tickets[i].toDate, 'HH:mm') +
              (i != element.tickets.length - 1 ? ' | ' : ''),
          );
        }
        return tickets;
      },
    },
    {
      key: 'price',
      value: ({ element }) => this.formatPricePipe.transform(ProductUtils.getPrice(element, this.products)),
      settings: { sizeStyle: { maxWidth: '100px' } },
    },
  ];

  constructor(
    private uiService: SpaUIService,
    private formatDatePipe: FormatDatePipe,
    private storeService: StoreService,
    private formatPricePipe: FormatPricePipe,
  ) {
    this.storeService
      .select('productFamilies')
      .pipe(take(1), untilDestroyed(this))
      .subscribe((element: SpaProductFamilyDTO[]) => (this.products = element));
  }

  loadGuestBookings(pagination: Pagination = { page: 0, pageSize: this.pageSize }): void {
    this.loading = true;
    this.uiService
      .getGuestBookings(this.guest.guestId, null, null, pagination.page, pagination.pageSize)
      .pipe(
        take(1),
        finalize(() => (this.loading = false)),
      )
      .subscribe((resp) => {
        this.bookings = wrapElements(resp);
      });
  }

  closeBooking() {
    this.closeExistingBookings.emit(this.guest?.guestId);
  }
}
