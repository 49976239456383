<div class="spa-list-flex">
  <spa-list
    [rows]="selectedGuests"
    [columns]="listColumns"
    [rowId]="'guestId'"
    [selectedRow]="existingBookingsGuestId"
    [rowsIdsWithHint]="guestsWithExistingBookings"
    [interactive]="clickOnGuest.observed"
    [hintText]="'text.booking.guest-has-already-booking' | transloco"
    (removeElement)="removeSelectedGuest($event)"
    (selectElement)="onClickSelectedGuest($event)"
    class="spa-list-flex-left"
  >
    <spa-list-template columnKey="price">
      <ng-template let-guest="row"> </ng-template>
    </spa-list-template>
    <spa-list-template columnKey="discount">
      <ng-template let-guest="row">
        <div class="additional-info-form" (click)="$event.preventDefault(); $event.stopPropagation()">
          @if (showDiscountForm && guestPrice) {
            <div
              *ngIf="showFormArray[guest | guestSelection: selectedGuests]"
              class="content"
              [formGroup]="guestForms[guest | guestSelection: selectedGuests]"
            >
              <dx-dropdown
                [label]="'label.button.discount.reason' | transloco"
                class="spa-list-dropdown-discountreason"
                formControlName="discountReason"
                (valueChange)="onDiscountReasonChange(guest, 'discountReason')"
                data-testid="discount_reason"
              >
                <dx-dropdown-option
                  *ngFor="let reason of spaDiscountReasonList.elements"
                  [label]="reason.title"
                  [value]="reason.title"
                ></dx-dropdown-option>
              </dx-dropdown>
              <dx-number-input
                min="1"
                [max]="isNewspaDiscountReasonList ? 50 : 100"
                nominmaxlabel="true"
                class="spa-booking-discount-input"
                [label]="'label.button.discount' | transloco"
                (valueChange)="onDiscountReasonChange(guest, 'percent')"
                formControlName="percent"
                profile="percent"
                data-testid="discount_percent"
              ></dx-number-input>
              <dx-number-input
                precision="2"
                nominmaxlabel="true"
                min="1"
                [max]="maxPrice_absolute"
                class="spa-booking-discount-input"
                label=" "
                (valueChange)="onDiscountReasonChange(guest, 'absolute')"
                formControlName="absolute"
                profile="currency-euro"
                data-testid="discount_price"
              ></dx-number-input>

              <div *ngIf="!isNewspaDiscountReasonList">
                <dx-text type="ps"> </dx-text>
                <dx-checkbox
                  formControlName="fulldiscount"
                  label="100%"
                  (checkedChange)="onDiscountReasonChange(guest, 'fulldiscount')"
                ></dx-checkbox>
              </div>

              <spa-button
                (click)="toggleFormVisibility(guest)"
                label="Close discount"
                shape="link"
                data-testid="toggle_discount_section_button"
              ></spa-button>
            </div>

            <spa-button
              *ngIf="!showFormArray[guest | guestSelection: selectedGuests]"
              (click)="toggleFormVisibility(guest)"
              label="Give discount"
              shape="link"
              data-testid="toggle_discount_section_button"
            ></spa-button>
          }
          <div>
            <div
              class="spa-selected-guest-price-container"
              [ngClass]="{ 'spa-total-guest-price-color': guestPrice && totalDiscountPrice === undefined }"
              *ngIf="totalDiscountPrice || guestPrice"
            >
              <div
                class="label-container spa-selected-guest-price-container__label"
                [ngClass]="{ 'spa-guest-selection-blue-color': guestPrice && totalDiscountPrice === undefined }"
              >
                <dx-text class="label-text style-infotext-small">{{
                  clickOnGuest.observed
                    ? guestPrice
                      ? ("label.booking.guest.price" | transloco)
                      : ""
                    : ("label.total" | transloco)
                }}</dx-text>
              </div>

              <dx-text
                [id]="'guestprice-' + (guest | guestSelection: selectedGuests)"
                class="spa-selected-guest-price-container__value style-paragraph-standard hydrated"
                [ngClass]="{
                  'spa-guest-selection-blue-color': guestPrice && totalDiscountPrice === undefined,
                  discounted:
                    guestForms[guest | guestSelection: selectedGuests].value.percent ||
                    guestForms[guest | guestSelection: selectedGuests].value.absolute ||
                    guestForms[guest | guestSelection: selectedGuests].controls['fulldiscount'].value,
                }"
                data-testid="guest_price_original"
              >
                {{
                  clickOnGuest.observed
                    ? (guestPrice | formatPricePipe)
                    : (totalDiscountPrice | formatPricePipe)
                      ? (totalDiscountPrice | formatPricePipe)
                      : ""
                }}
              </dx-text>
            </div>

            <div>
              <dx-text
                class="spa-list-discountprice"
                *ngIf="guestForms[guest | guestSelection: selectedGuests].get('priceInput').value"
                data-testid="guest_price_discounted"
                >{{ guestForms[guest | guestSelection: selectedGuests].get("priceInput").value }} €</dx-text
              >
            </div>
          </div>
        </div>
      </ng-template>
    </spa-list-template>
  </spa-list>
</div>

<div
  class="spa-total-guest-price"
  *ngIf="selectedGuests && selectedGuests.length > 0 && totalDiscountPrice !== undefined"
>
  <div class="spa-selected-guest-price-container">
    <div class="label-container spa-selected-guest-price-container__label spa-guest-selection-blue-color">
      <dx-text class="label-text style-infotext-small">{{ "label.total" | transloco }}</dx-text>
    </div>
    <dx-text
      id="price-total"
      class="spa-selected-guest-price-container__value spa-guest-selection-blue-color style-paragraph-standard hydrated"
      data-testid="total_price"
    >
      {{ totalDiscountPrice | formatPricePipe }}
    </dx-text>
  </div>
</div>

<ng-container *ngIf="selectMultipleGuests || (!selectMultipleGuests && selectedGuests?.length < 1)">
  <div
    class="spa-autocomplete-search-container"
    [ngClass]="{
      'spa-guest-selection-margin-top': selectedGuests && selectedGuests.length > 0 && !totalDiscountPrice,
    }"
  >
    <spa-autocomplete-search
      class="spa-search"
      [rows]="guestSearchResults"
      [searching]="searching"
      [checkboxSelectionType]="checkboxSelectionType"
      [columns]="autocompleteColumns"
      (fetchData)="loadGuests($event)"
      (selectRow)="onSelectGuests($event)"
      [isRequired]="true"
      data-testid="guest_search"
    >
      <spa-autocomplete-template columnKey="imageUri">
        <ng-template let-guest="row">
          <img
            [src]="guest.data.imageUri | imageUrl"
            alt="{{ guest.data.lastName }}, {{ guest.data.firstName }}"
            class="spa-guest-selection-autocomplete-search-img"
          />
        </ng-template>
      </spa-autocomplete-template>
    </spa-autocomplete-search>
  </div>
</ng-container>
