import { Pipe, PipeTransform } from '@angular/core';
import { GuestDTO } from '@api';

@Pipe({
  name: 'guestSelection',
  standalone: true,
})
export class GuestSelectionPipe implements PipeTransform {
  transform(guest: GuestDTO, guestList: GuestDTO[]): number {
    return guestList.findIndex((g) => g.guestId === guest.guestId);
  }
}
