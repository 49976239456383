// @ts-strict-ignore
import { ChangeDetectionStrategy, Component, Inject, input, OnInit, signal } from '@angular/core';
import { SPA_DIALOG, SpaModal } from '@services';
import { GuestDTO, MessagesBodyDTO, SpaBookingDTO, SpaBookingOrderDTO, SpaBookingResultDTO } from '@api';
import { ModalComponentButtonConfig } from '../../../../components/modal/modal.component';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import _ from 'lodash';
import { Column, Row, wrap } from '../../../../components/spa-table/spa-table.component';
import { FormatDatePipe } from '@pipe';
import { FormatPricePipe } from '../../../../pipes/format-price.pipe';
import { CalculateDiscountPipe } from '../../../../pipes/calculate-discount.pipe';

interface FailedBookingMessagesRowType {
  guestId: string;
  failureMessage: MessagesBodyDTO;
}

@Component({
  selector: 'spa-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss'],
  providers: [FormatDatePipe, FormatPricePipe, CalculateDiscountPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingConfirmationComponent implements OnInit {
  bookingRows = signal<Row<SpaBookingDTO>[]>([]);
  failedBookingsMessagesRows = signal<Row<FailedBookingMessagesRowType>[]>([]);

  bookingResults = input.required<SpaBookingResultDTO[]>();
  bookingRequest = input.required<SpaBookingOrderDTO[]>();
  guests = input.required<GuestDTO[]>();
  differentPayer = input.required<GuestDTO[]>();

  headlineLabel = signal<string>(this.transloco.translate('label.booking.booking-success-modal'));
  totalPrice = signal<string | undefined>(undefined);

  buttons: ModalComponentButtonConfig[] = [
    {
      label: this.transloco.translate('label.button.further-booking'),
      primary: false,
      action: () => {
        this.modal.close();
        window.location.reload();
      },
      dataTestID: 'further_booking_button',
    },
    {
      label: this.transloco.translate('label.button.go-to-central-page'),
      primary: true,
      action: () => {
        this.modal.close();
        this.router.navigate(['']);
      },
      dataTestID: 'go_to_central_page_button',
    },
  ];

  tableColumns: Column<SpaBookingDTO>[] = [
    {
      key: 'name',
      translationKey: 'label.last-name-first-name',
      value: ({ element }) =>
        this.guestById(element.guestId)?.lastName + ', ' + this.guestById(element.guestId)?.firstName,
    },
    {
      key: 'ticket-product',
      value: ({ element }) => (element.event?.name ? element.event.name : element.product.name),
    },
    {
      key: 'date-time',
      translationKey: 'column.booking-confirm-table.date-time',
      value: ({ element }) => {
        if (element.tickets.length <= 1) {
          return (
            this.formatDatePipe.transform(element.tickets[0].fromDate, 'DD.MM.YYYY, HH:mm') +
            ' - ' +
            this.formatDatePipe.transform(element.tickets[0].toDate, 'HH:mm')
          );
        } else if (element.tickets.length > 1) {
          return (
            this.formatDatePipe.transform(element.tickets[0].fromDate, 'DD.MM.YYYY') +
            ' ... ' +
            this.formatDatePipe.transform(element.tickets[element.tickets.length - 1].toDate, 'DD.MM.YYYY')
          );
        } else {
          return '-';
        }
      },
      settings: { sizeStyle: { maxWidth: '140px' } },
    },

    {
      key: 'price',
      value: ({ element }) => {
        if (element.discount && element.discount.amount !== undefined && element.discount.unit !== undefined) {
          return this.calculateDiscountPrice.transform(
            element.product.price,
            element.discount.amount,
            element.discount.unit,
          );
        } else {
          return this.formatPricePipe.transform(element.product.price);
        }
      },
      settings: { align: 'right', sizeStyle: { maxWidth: '100px' } },
    },
  ];

  failedBookingsTableColumns: Column<{ guestId: string; failureMessage: MessagesBodyDTO }>[] = [
    {
      key: 'name',
      translationKey: 'label.last-name-first-name',
      settings: { sizeStyle: { maxWidth: '200' } },
      value: ({ element }) =>
        this.guestById(element.guestId)?.lastName + ', ' + this.guestById(element.guestId)?.firstName,
    },
    {
      key: 'cabin-no',
      translationKey: 'label.cabin-no',
      settings: { sizeStyle: { maxWidth: '80' } },
      value: ({ element }) => this.guestById(element.guestId)?.cabinNumber,
    },
    {
      key: 'message',
      translationKey: 'column.booking-confirm-table.error-messages',
      value: ({ element }) => {
        const messages = element.failureMessage.localizations
          .filter((localization) => localization.locale === 'en')
          .map((localization) => localization.items);
        return messages.flatMap((message) => Object.values(message).join(' - '));
      },
    },
  ];

  ngOnInit() {
    this.setModalHeadline();
    this.calculateTotalPrice();
    this.bookingRows.set(
      this.bookingResults()
        .filter((result) => result.booking)
        .map((result) => wrap(result.booking)),
    );
    if (this.bookingResults().filter((result) => result.failureMessage).length > 0) {
      this.failedBookingsMessagesRows.set(
        this.bookingResults()
          .map((result, index) => {
            return { ...result, guestId: this.bookingRequest()[index].guestId };
          })
          .filter((result) => result.failureMessage)
          .map((result) => wrap({ guestId: result.guestId, failureMessage: result.failureMessage })),
      );
    }
  }

  constructor(
    @Inject(SPA_DIALOG) private modal: SpaModal<GuestDTO>,
    private transloco: TranslocoService,
    private router: Router,
    private formatDatePipe: FormatDatePipe,
    private formatPricePipe: FormatPricePipe,
    private calculateDiscountPrice: CalculateDiscountPipe,
  ) {}

  private guestById(guestId: string): GuestDTO {
    return _.find(this.guests(), { guestId });
  }

  private setModalHeadline() {
    if (this.bookingResults().some((result) => result.booking)) {
      if (this.bookingResults().some((result) => result.failureMessage)) {
        this.headlineLabel.set(this.transloco.translate('label.booking.booking-partial-success-modal'));
        return;
      }
      this.headlineLabel.set(this.transloco.translate('label.booking.booking-success-modal'));
    } else if (this.bookingResults().filter((result) => result.failureMessage).length > 0) {
      this.headlineLabel.set(this.transloco.translate('label.booking.booking-failed-modal'));
    }
  }

  private calculateTotalPrice() {
    this.totalPrice.set(
      `${this.bookingResults()
        .reduce((acc, result) => {
          if (result.booking) {
            return acc + result.booking.netPrice;
          }
          return acc;
        }, 0)
        .toFixed(2)} €`,
    );
  }
}
