<div class="spa-existing-bookings-container">
  <div class="spa-existing-bookings-container__header">
    <div class="spa-existing-bookings-container__title">{{ "subtitle.booking.existing-bookings" | transloco }}</div>
    <spa-button (clickedButton)="closeBooking()" id="guest-btn-close" shape="link" asset="close"></spa-button>
  </div>
  <div class="spa-existing-bookings-container__subtitle">{{ guest?.firstName }} {{ guest?.lastName }}</div>

  <spa-table
    [rows]="bookings"
    [columns]="tableColumns"
    [rowId]="'bookingId'"
    [noDataLabel]="'text.existing-bookings.no-bookings-available' | transloco"
    [loading]="loading"
    (paginate)="loadGuestBookings($event)"
  ></spa-table>
</div>
