// @ts-strict-ignore
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateProduct, DateRange, ProductFamilyOrEventProduct } from '@model';
import _ from 'lodash';
import { StringOrObject } from '@compeople-shared/design-system';
import moment from 'moment';
import { Moment } from 'moment';
import { SpaProductDTO } from '@api';
import { HasProductValidTimePipe } from '@pipe';

@Component({
  selector: 'spa-time-selection',
  templateUrl: './time-selection.component.html',
  styleUrls: ['./time-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimeSelectionComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSelectionComponent implements ControlValueAccessor {
  productFamilyOrEventProduct: ProductFamilyOrEventProduct;
  selectedProductId: string;

  from: Moment;
  to: Moment;
  disabled: boolean;

  minDate: Moment;

  maxDate: Moment;

  multiDayRanges: DateRange[];

  get validProducts(): SpaProductDTO[] {
    if (this.isEventTicket()) {
      const product: SpaProductDTO = this.productFamilyOrEventProduct?.eventProduct.product;
      const valid = this.hasProductValidTimePipe.transform(product, this.from);
      return valid ? [product] : [];
    }

    return this.productFamilyOrEventProduct?.productFamily.products?.filter((product: SpaProductDTO) =>
      this.hasProductValidTimePipe.transform(product, this.from),
    );
  }

  constructor(private hasProductValidTimePipe: HasProductValidTimePipe) {
    this.updateMinMaxDate();
  }

  // TODO: fix eslint error
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('productFamilyOrEventProduct') set setProductFamily(productFamilyOrEventProduct: ProductFamilyOrEventProduct) {
    this.multiDayRanges = [];
    this.updateMinMaxDate();
    this.productFamilyOrEventProduct = productFamilyOrEventProduct;
    this.setDisabledState(!productFamilyOrEventProduct);

    if (this.isTimeslotTicket()) {
      this.from = moment();
      if (productFamilyOrEventProduct?.productFamily) {
        let firstPossibleProduct: SpaProductDTO;
        while (!firstPossibleProduct) {
          firstPossibleProduct = this.productFamilyOrEventProduct.productFamily.products.find(
            (product: SpaProductDTO) => this.hasProductValidTimePipe.transform(product, this.from),
          );
          if (firstPossibleProduct) {
            break;
          } else {
            this.minDate = this.minDate.add(1, 'days');
            this.from = this.from.add(1, 'days');
          }
        }
        this.selectedProductId = firstPossibleProduct.productId;
      } else {
        this.selectedProductId = null;
      }
    } else if (this.isFlexibleTicket()) {
      this.selectedProductId = this.productFamilyOrEventProduct
        ? this.productFamilyOrEventProduct.productFamily.products[0].productId
        : null;
      this.from = moment();
    } else if (this.isMultidayTicket()) {
      this.selectedProductId = this.productFamilyOrEventProduct
        ? this.productFamilyOrEventProduct.productFamily.products[0].productId
        : null;
      this.from = null;
      this.to = null;
    } else if (this.isEventTicket()) {
      this.selectedProductId = this.productFamilyOrEventProduct
        ? this.productFamilyOrEventProduct.eventProduct.product.productId
        : null;
      this.from = moment(this.productFamilyOrEventProduct.eventProduct.fromDate);
      this.to = moment(this.productFamilyOrEventProduct.eventProduct.toDate);
    }

    setTimeout(() => {
      this.emit();
    });
  }

  get selectedProduct(): SpaProductDTO {
    if (this.productFamilyOrEventProduct && this.selectedProductId) {
      if (this.isEventTicket()) {
        return this.productFamilyOrEventProduct?.eventProduct.product;
      }

      return _.find(this.productFamilyOrEventProduct.productFamily.products, { productId: this.selectedProductId });
    }

    return null;
  }

  get selectedDates(): DateRange[] {
    let dates: DateRange[] = [];

    if (!this.selectedProduct) {
      return dates;
    }

    if (this.isTimeslotTicket()) {
      const timeSlotFrom: Moment = moment(this.selectedProduct.timeslot.from, 'HH:mm');
      const timeSlotTo: Moment = moment(this.selectedProduct.timeslot.to, 'HH:mm');

      dates = [
        {
          from: moment(this.from)
            .hours(timeSlotFrom.hours())
            .minutes(timeSlotFrom.minutes())
            .seconds(0)
            .milliseconds(0),
          to: moment(this.from.clone())
            .hours(timeSlotTo.hours())
            .minutes(timeSlotTo.minutes())
            .seconds(0)
            .milliseconds(0),
        },
      ];
    } else if (this.isFlexibleTicket()) {
      const now = moment();
      dates = [
        {
          from: now,
          to: now.clone().add(this.selectedProduct.duration, 'minute'),
        },
      ];
    } else if (this.isMultidayTicket()) {
      return this.multiDayRanges;
    } else if (this.isEventTicket()) {
      dates = [
        {
          from: moment(this.from),
          to: moment(this.to),
        },
      ];
    }

    return dates;
  }

  isEventTicket() {
    return !!this.productFamilyOrEventProduct?.eventProduct;
  }

  isTimeslotTicket() {
    return this.productFamilyOrEventProduct?.productFamily?.family.durationType === 'timeslot';
  }

  isMultidayTicket() {
    return this.productFamilyOrEventProduct?.productFamily?.family.durationType === 'multiday';
  }

  isFlexibleTicket() {
    return this.productFamilyOrEventProduct?.productFamily?.family.durationType === 'flexible';
  }

  updateMinMaxDate(): void {
    this.minDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    this.maxDate = moment().set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).add(30, 'days');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (dateProduct: DateProduct) => {
    /** do nothing */
  };

  onTouched = () => {
    /** do nothing */
  };

  emit() {
    if (this.selectedProductId && this.from) {
      if (this.isMultidayTicket()) {
        if (this.to && this.countDaysLeftForMultiRange() === 0) {
          this.onChange({ dateRanges: this.selectedDates, product: this.selectedProduct });
        } else {
          this.onChange(null);
        }
      } else {
        this.onChange({ dateRanges: this.selectedDates, product: this.selectedProduct });
      }
    } else {
      this.onChange(null);
    }
  }

  updateMinDate() {
    const firstPossibleProduct = this.productFamilyOrEventProduct.productFamily.products.find(
      (product: SpaProductDTO) => this.hasProductValidTimePipe.transform(product, moment(this.minDate)),
    );
    if (!firstPossibleProduct) {
      this.minDate = this.minDate.add(1, 'days');
    }
  }

  changeDateForTimeslotTicket(date: string) {
    this.from = moment(date);
    this.to = moment(date);

    if (
      (this.selectedProduct && !this.hasProductValidTimePipe.transform(this.selectedProduct, this.from)) ||
      !this.selectedProductId
    ) {
      const firstPossibleProduct = this.productFamilyOrEventProduct.productFamily?.products.find(
        (product: SpaProductDTO) => this.hasProductValidTimePipe.transform(product, this.from),
      );
      this.selectedProductId = firstPossibleProduct ? firstPossibleProduct.productId : null;
    }
    this.emit();
  }

  removeDateRange(range: DateRange) {
    this.multiDayRanges = _.filter(this.multiDayRanges, (r) => !range.from.isSame(r.from));
    this.emit();
  }

  countDaysLeftForMultiRange() {
    return this.selectedProduct.ticketCount - this.multiDayRanges.length;
  }

  changeDateForMultidayTicket(date: string, rangeEnd: 'from' | 'to') {
    if (date) {
      if (rangeEnd === 'from') {
        this.from = moment(date);
      } else {
        this.to = moment(date);

        // multiday selection
        let start = this.from.clone();
        const end = this.to.clone();
        while (start.isSameOrBefore(end)) {
          if (this.countDaysLeftForMultiRange() > 0 && !_.find(this.multiDayRanges, (d) => d.from.isSame(start))) {
            this.multiDayRanges.push({
              from: start.clone().startOf('day'),
              to: start.clone().endOf('day'),
            });
          }
          start = start.add(1, 'days');
        }

        this.multiDayRanges = _.orderBy(this.multiDayRanges, (r) => r.from);
      }
    }

    this.emit();
  }

  changeProduct({ detail }: CustomEvent<StringOrObject>) {
    this.selectedProductId = detail as string;

    if (this.isFlexibleTicket()) {
      this.from = moment();
    } else if (this.isMultidayTicket()) {
      if (this.selectedProduct.ticketCount < this.multiDayRanges.length) {
        this.multiDayRanges = this.multiDayRanges.slice(
          0,
          -(this.multiDayRanges.length - this.selectedProduct.ticketCount),
        );
      }
    }

    this.emit();
  }

  registerOnChange(onChange: (dateProduct: DateProduct) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  writeValue(dateProduct: DateProduct): void {
    /** do nothing */
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
