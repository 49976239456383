<spa-modal [buttons]="buttons" [title]="headlineLabel()" width="m" data-testid="booking_summary_dialog">
  <div slot="content">
    <spa-table
      [columns]="tableColumns"
      [rows]="bookingRows()"
      [rowId]="'bookingId'"
      data-testid="successful_bookings_table"
    ></spa-table>
    @if (failedBookingsMessagesRows() && failedBookingsMessagesRows().length > 0) {
      <div class="margin-top" data-testid="failed_bookings_summary">
        <div>{{ "label.booking.booking-failed-headline" | transloco }}</div>
        <spa-table
          [columns]="failedBookingsTableColumns"
          [rows]="failedBookingsMessagesRows()"
          data-testid="failed_bookings_table"
        ></spa-table>
      </div>
    }
    <div
      *ngIf="differentPayer() && this.differentPayer().length > 0"
      class="spa-booking-confirmation-payer-container margin-top"
      data-testid="payer_summary"
    >
      <div>
        <div class="spa-booking-confirmation-label">{{ "label.booking-confirm.payer" | transloco }}</div>
        <div class="spa-booking-confirmation-value" data-testid="payer_name">
          {{ differentPayer()[0].lastName }}, {{ differentPayer()[0].firstName }}
        </div>
      </div>
      <div>
        <div class="spa-booking-confirmation-label spa-booking-confirmation-price-label">
          {{ "label.booking-confirm.total" | transloco }}
        </div>
        <div class="spa-booking-confirmation-value" data-testid="payer_price">
          {{ totalPrice() }}
        </div>
      </div>
    </div>
  </div>
</spa-modal>
