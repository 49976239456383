// @ts-strict-ignore
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { SpaDayLoadDTO, SpaDayLoadListDTO, SpaLoadService } from '@api';
import { StoreService } from '@state';
import { Observable, tap } from 'rxjs';
import { ScrollToConfigOptions, ScrollToService, TimeSchedule, TimeScheduleService } from '@services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'spa-load-view',
  templateUrl: './load-view.component.html',
  styleUrls: ['./load-view.component.scss'],
})
export class LoadViewComponent implements OnInit {
  dayLoads$: Observable<SpaDayLoadDTO[]> = this.storeService.select('dayLoads');

  DAY_RANGE = 30;
  rangeStart = moment();
  rangeEnd = moment().add(this.DAY_RANGE - 1, 'day');

  startIndex = 0;
  endIndex = 3;
  steps = 3;

  showLoad = false;

  get windowInnerWidth(): number {
    return window.innerWidth;
  }

  constructor(
    private scrollToService: ScrollToService,
    private storeService: StoreService,
    private spaLoadService: SpaLoadService,
    private scheduleService: TimeScheduleService,
  ) {
    this.scheduleService.addSchedule(new TimeSchedule(0, 180000, this.fetchDayLoad(), untilDestroyed(this)));
  }

  fetchDayLoad() {
    return this.spaLoadService
      .getDailyLoad(this.rangeStart.format('YYYY-MM-DD'), this.rangeEnd.format('YYYY-MM-DD'))
      .pipe(
        tap((load: SpaDayLoadListDTO) => {
          this.storeService.set('dayLoads', load.elements);
        }),
      );
  }

  @ViewChild('load') load$: ElementRef;

  ngOnInit(): void {
    this.updateIndex();
  }

  @HostListener('window:resize', []) onResize() {
    this.updateIndex();
  }

  updateIndex() {
    if (this.windowInnerWidth >= 1280) {
      this.steps = 3;
      while (this.endIndex - this.startIndex < this.steps) {
        ++this.endIndex;
      }
    } else if (this.windowInnerWidth >= 1024) {
      this.steps = 2;
      while (this.endIndex - this.startIndex < this.steps) {
        ++this.endIndex;
      }
      while (this.endIndex - this.startIndex > this.steps) {
        --this.endIndex;
      }
    } else if (this.windowInnerWidth < 1024) {
      this.steps = 1;
      while (this.endIndex - this.startIndex > this.steps) {
        --this.endIndex;
      }
    }
  }

  previousDay() {
    let count = 0;
    while (this.startIndex > 0 && count < this.steps) {
      --this.startIndex;
      --this.endIndex;
      ++count;
    }
  }

  nextDay() {
    let count = 0;
    while (this.endIndex < this.DAY_RANGE && count < this.steps) {
      ++this.startIndex;
      ++this.endIndex;
      ++count;
    }
  }

  disablePreviousDayButton(): boolean {
    return this.startIndex === 0;
  }

  disableNextDayButton(): boolean {
    return this.endIndex >= this.DAY_RANGE;
  }

  isToday(dateString: string): boolean {
    const date = moment(dateString);
    return date.isSame(moment(), 'day');
  }

  isTomorrow(dateString: string): boolean {
    const date = moment(dateString);
    return date.isSame(moment().add('1', 'day'), 'day');
  }

  onShowLoad() {
    this.showLoad = !this.showLoad;

    setTimeout(() => {
      const config: ScrollToConfigOptions = {
        target: this.load$,
        offset: -64,
      };
      this.scrollToService.scrollTo(config);
    });
  }
}
