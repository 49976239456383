// @ts-strict-ignore
import { Component, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProductFamily, ProductFamilyOrEventProduct } from '@model';
import { map, Observable, tap } from 'rxjs';
import { SpaProductsByFamilyDTO, SpaUIService, UiSpaEventDTO } from '@api';
import { StoreService } from '@state';
import _ from 'lodash';
import { TimeSchedule, TimeScheduleService } from '@services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'spa-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProductSelectionComponent),
    },
  ],
})
export class ProductSelectionComponent implements ControlValueAccessor {
  @HostBinding('class') cls = 'spa-product-selection';
  @HostBinding('class.disabled') disabled: boolean;

  selectedItem: ProductFamilyOrEventProduct = null;

  productsByFamily$: Observable<SpaProductsByFamilyDTO[]> = this.storeService.select('productsByFamily');

  events$: Observable<UiSpaEventDTO[]> = this.storeService.select('productsByEvent').pipe(
    map((productsByEvent) => {
      let events: UiSpaEventDTO[] = [];
      _.forEach(productsByEvent, (pbe) => (events = events.concat(pbe.events)));
      return events;
    }),
  );

  constructor(
    private storeService: StoreService,
    private uiService: SpaUIService,
    private scheduleService: TimeScheduleService,
  ) {
    this.scheduleService.addSchedule(new TimeSchedule(0, 180000, this.loadEvents(), untilDestroyed(this)));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (item: ProductFamilyOrEventProduct) => {
    /** do nothing */
  };

  onTouched = () => {
    /** do nothing */
  };

  selectProductFamily(productFamily: ProductFamily) {
    this.selectedItem = {
      productFamily,
    };
    this.onChange(this.selectedItem);
  }

  selectEventProduct(eventProduct: UiSpaEventDTO) {
    this.selectedItem = {
      eventProduct,
    };
    this.onChange(this.selectedItem);
  }

  clearSelection() {
    this.selectedItem = null;
    this.onChange(this.selectedItem);
  }

  registerOnChange(onChange: (item: ProductFamilyOrEventProduct) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  writeValue(productFamilyOrEventProduct: ProductFamilyOrEventProduct): void {
    this.selectedItem = productFamilyOrEventProduct;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  loadEvents() {
    return this.uiService.getProductsOverview().pipe(
      tap((resp) => {
        this.storeService.set('productsByFamily', resp.productsByFamily);
        this.storeService.set('productsByEvent', resp.productsByEvent);
      }),
    );
  }
}
