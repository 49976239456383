<div class="spa-products">
  <ng-container *ngIf="!selectedItem">
    <spa-product-family
      (select)="selectProductFamily($event)"
      *ngFor="let productFamily of productsByFamily$ | async; index as i"
      [label]="'button.ticket.select' | transloco"
      [ngModel]="productFamily"
      data-testid="product_ticket"
    ></spa-product-family>

    <spa-event-product
      (select)="selectEventProduct($event)"
      *ngFor="let item of events$ | async; index as i"
      [label]="'button.ticket.select' | transloco"
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="item"
      [id]="item.eventId"
      data-testid="event_ticket"
    ></spa-event-product>
  </ng-container>

  <ng-container *ngIf="selectedItem">
    <div class="spa-selection">
      <spa-product-family
        *ngIf="selectedItem?.productFamily"
        [ngModel]="selectedItem.productFamily"
        data-testid="product_ticket"
      ></spa-product-family>
      <spa-event-product
        *ngIf="selectedItem?.eventProduct"
        [ngModel]="selectedItem.eventProduct"
        data-testid="event_ticket"
      ></spa-event-product>
      <spa-button
        (clickedButton)="clearSelection()"
        label="{{ 'button.booking.change-ticket' | transloco }}"
        shape="link"
        data-testid="clear_ticket_selection_button"
      ></spa-button>
    </div>
  </ng-container>
</div>
