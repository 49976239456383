<div class="spa-buttons">
  <spa-button
    (clickedButton)="onShowLoad()"
    label="{{
      showLoad ? ('button.bookings.time.hide-load' | transloco) : ('button.bookings.time.show-load' | transloco)
    }}"
    shape="link"
  ></spa-button>
</div>
<div #load *ngIf="showLoad" class="spa-timing-load">
  <ng-container *ngIf="dayLoads$ | async as dayLoads">
    <spa-button asset="arrow-left" (clickedButton)="previousDay()" [disabled]="disablePreviousDayButton()"></spa-button>
    <div class="spa-timing-load-content">
      <div class="spa-timing-load-days">
        <div
          *ngFor="let dateAndLoad of dayLoads | slice: startIndex : endIndex; index as i"
          class="spa-timing-load-chart"
        >
          <div class="spa-timing-load-days__adverbs">
            {{
              isToday(dateAndLoad?.day)
                ? ("home-capacity-today-label" | transloco) + " -"
                : isTomorrow(dateAndLoad?.day)
                  ? ("home-capacity-tomorrow-label" | transloco) + " -"
                  : ""
            }}
          </div>
          <div>{{ dateAndLoad?.load[0]?.period?.from | formatDate: "ddd DD.MMMM YYYY" }}</div>
        </div>
      </div>
      <div class="spa-timing-load-charts">
        <div
          *ngFor="let dateAndLoad of dayLoads | slice: startIndex : endIndex; index as i"
          class="spa-timing-load-chart"
        >
          <spa-bar-chart [loads]="dateAndLoad"></spa-bar-chart>
        </div>
      </div>
    </div>
    <spa-button asset="arrow-right" (clickedButton)="nextDay()" [disabled]="disableNextDayButton()"></spa-button>
  </ng-container>
</div>
