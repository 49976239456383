<dx-grid mq3="12 / 12" mq4="6-6/*">
  <!-- No ticket is slected -->
  <div *ngIf="!productFamilyOrEventProduct" class="spa-no-ticket-hint">
    {{ "text.select-ticket-first" | transloco }}
  </div>

  <!-- Event -->
  <ng-container *ngIf="isEventTicket()">
    <dx-date-input
      [disabled]="true"
      [value]="productFamilyOrEventProduct.eventProduct.fromDate | formatDate: 'YYYY-MM-DD'"
      label="{{ 'label.input.bookings.time.day' | transloco }}"
      [max]="maxDate | formatDate: 'YYYY-MM-DD'"
      [min]="minDate | formatDate: 'YYYY-MM-DD'"
    ></dx-date-input>

    <dx-text-input
      [disabled]="true"
      value="{{ productFamilyOrEventProduct.eventProduct.fromDate | formatDate: 'HH:mm' }} - {{
        productFamilyOrEventProduct.eventProduct.toDate | formatDate: 'HH:mm'
      }}"
      id="event-time"
      label="{{ 'label.input.bookings.time.time' | transloco }}"
      placeholder=""
    >
    </dx-text-input>
  </ng-container>

  <!-- Zeitkarte -->
  <ng-container *ngIf="isTimeslotTicket()">
    <dx-date-input
      (valueChange)="changeDateForTimeslotTicket($event.detail)"
      [disabled]="disabled"
      [value]="from | formatDate: 'YYYY-MM-DD'"
      label="{{ 'label.input.bookings.time.day' | transloco }}"
      [max]="maxDate"
      [min]="minDate"
      (focusin)="updateMinDate()"
      required="true"
      data-testid="date"
    ></dx-date-input>
    <dx-dropdown
      (valueChange)="changeProduct($event)"
      [disabled]="disabled"
      [value]="selectedProductId"
      id="time-dropdown"
      label="{{ 'label.input.bookings.time.time' | transloco }}"
      placeholder="{{
        from?.isValid() ? ('placeholder.select-time' | transloco) : ('placeholder.select-day' | transloco)
      }}"
      required="true"
      data-testid="time"
    >
      <ng-container *ngFor="let product of validProducts">
        <dx-dropdown-option
          [label]="
            'label.time-and-price'
              | transloco: { time: (product.timeslot | timeSlot), price: product.price | formatPricePipe }
          "
          [value]="product.productId"
        ></dx-dropdown-option>
      </ng-container>
    </dx-dropdown>
  </ng-container>

  <!-- Mehrtageskarte -->
  <ng-container *ngIf="isMultidayTicket()">
    <div class="spa-multi-dates-wrapper">
      <div class="spa-variant">
        <div class="spa-variant-days">
          <dx-dropdown
            (valueChange)="changeProduct($event)"
            [disabled]="disabled"
            [value]="selectedProductId"
            id="day-dropdown"
            label="{{ 'label.input.bookings.time.duration' | transloco }}"
            placeholder=""
            required="true"
            data-testid="duration"
          >
            <dx-dropdown-option
              *ngFor="let product of productFamilyOrEventProduct?.productFamily?.products"
              label="{{
                'label.product-days-price'
                  | transloco
                    : {
                        days: product.ticketCount,
                        nominativeDay: product.ticketCount === 1 ? ('text.day' | transloco) : ('text.days' | transloco),
                        price: product.price | formatPricePipe,
                      }
              }}"
              [value]="product.productId"
            ></dx-dropdown-option>
          </dx-dropdown>

          <div class="spa-count-days" data-testid="left_spa_days_counter" *ngIf="countDaysLeftForMultiRange()">
            {{
              "text.count-remaining-days"
                | transloco
                  : {
                      count: countDaysLeftForMultiRange(),
                      nominativeDay:
                        countDaysLeftForMultiRange() === 1 ? ("text.day" | transloco) : ("text.days" | transloco),
                    }
            }}
          </div>
        </div>

        <div class="spa-days">
          <div class="spa-day" *ngFor="let range of multiDayRanges">
            <span>{{ range.from | formatDate: "DD.MM." }}</span>
            <spa-icon fontSize="16px" (click)="removeDateRange(range)">close</spa-icon>
          </div>
        </div>
      </div>

      <dx-date-range [max]="maxDate | formatDate: 'YYYY-MM-DD'" [min]="minDate | formatDate: 'YYYY-MM-DD'">
        <dx-date-input
          [disabled]="countDaysLeftForMultiRange() === 0"
          slot="from"
          [value]="from | formatDate: 'YYYY-MM-DD'"
          (valueChange)="changeDateForMultidayTicket($event.detail, 'from')"
          label="{{ 'label.input.bookings.time.from' | transloco }}"
          required="true"
          data-testid="from_date"
        ></dx-date-input>
        <dx-date-input
          [disabled]="countDaysLeftForMultiRange() === 0"
          slot="to"
          [value]="to | formatDate: 'YYYY-MM-DD'"
          label="{{ 'label.input.bookings.time.to' | transloco }}"
          (valueChange)="changeDateForMultidayTicket($event.detail, 'to')"
          required="true"
          data-testid="to_date"
        ></dx-date-input>
      </dx-date-range>
    </div>
  </ng-container>

  <!-- Flexkarte -->
  <ng-container *ngIf="isFlexibleTicket()">
    <dx-date-input
      [disabled]="true"
      [value]="from | formatDate: 'YYYY-MM-DD'"
      label="{{ 'label.input.bookings.time.day' | transloco }}"
      required="true"
    ></dx-date-input>

    <dx-dropdown
      (valueChange)="changeProduct($event)"
      [disabled]="disabled"
      [value]="selectedProductId"
      id="flex-dropdown"
      label="{{ 'label.input.bookings.time.duration' | transloco }}"
      placeholder=""
      required="true"
      data-testid="duration"
    >
      <dx-dropdown-option
        *ngFor="let product of productFamilyOrEventProduct?.productFamily?.products"
        label="{{ product.duration + 'min' }}"
        [value]="product.productId"
      ></dx-dropdown-option>
    </dx-dropdown>
  </ng-container>
</dx-grid>
