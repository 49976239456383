// @ts-strict-ignore
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { lastValueFrom, take } from 'rxjs';
import {
  GuestDTO,
  GuestsService,
  PaginatedGuestDTO,
  SpaDiscountDTO,
  SpaDiscountReasonListDTO,
  SpaDiscountService,
  SpaDiscountUnitDTO,
} from '@api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _ from 'lodash';
import {
  AutocompleteColumn,
  AutocompleteRow,
} from '../../../../components/autocomplete-search/autocomplete-search.component';
import { FormatDatePipe } from '@pipe';
import { positionedListColumn } from '../../../../components/spa-list/spa-list.component';
import { BookingService } from '../../../../services/booking.service';
import { GuestSelectionPipe } from './pipes/guest-selection.pipe';

@UntilDestroy()
@Component({
  selector: 'spa-guest-selection',
  templateUrl: './guest-selection.component.html',
  styleUrls: ['./guest-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => GuestSelectionComponent),
    },
  ],
})
export class GuestSelectionComponent implements ControlValueAccessor, OnInit, OnChanges {
  // if control allows multiple guests
  @Input() selectMultipleGuests = true;
  // if control allows checkboxes in autocomplete and if multiple guests can be checked
  @Input() checkboxSelectionType: 'single' | 'multiple' | 'none';
  @Input() guestPrice: number;
  @Input() showDiscountForm = false;
  @Input() totalPrice: number;
  @Input() existingBookingsGuestId: string = null;
  @Input() showExistingBookings = false;
  @Input() guestsWithExistingBookings: string[] = [];
  @Input() isDifferentPayer = false;
  @Output() clickOnGuest = new EventEmitter<GuestDTO | undefined>();
  @Output() discountChanged = new EventEmitter<SpaDiscountDTO[] | undefined>();

  searching = false;
  guestSearchResults: AutocompleteRow<GuestDTO>[] | null = null;
  selectedGuests: GuestDTO[] = [];
  totalDiscountPrice: number;
  spaDiscountReasonList: SpaDiscountReasonListDTO;
  guestForms: FormGroup[] = [];
  showFormArray: boolean[] = new Array(this.guestForms.length).fill(false);
  maxPrice_absolute: number;

  isNewspaDiscountReasonList = false;

  autocompleteColumns: AutocompleteColumn<GuestDTO>[] = [
    {
      key: 'imageUri',
      value: () => '',
    },
    {
      key: 'lastName',
      widthInPercent: '30%',
    },
    {
      key: 'firstName',
      widthInPercent: '30%',
    },
    {
      key: 'birthdate',
      value: (row) => this.formatDate.transform(row.birthdate, 'DD.MM.YYYY'),
      widthInPercent: '20%',
    },
    {
      key: 'cabinNumber',
      widthInPercent: '10%',
    },
  ];

  listColumns: positionedListColumn<GuestDTO> = {
    left: [
      {
        key: 'last-name-first-name',
        value: (element) => `${element.lastName}, ${element.firstName}`,
        size: 'medium',
      },
      {
        key: 'cabin-no',
        value: (element) => element.cabinNumber,
        size: 'small',
      },
      {
        key: 'discount',
      },
    ],
  };

  constructor(
    private guestsService: GuestsService,
    private formatDate: FormatDatePipe,
    private spaDiscount: SpaDiscountService,
    private bookingService: BookingService,
    private formBuilder: FormBuilder,
    private readonly guestSelectionPipe: GuestSelectionPipe,
  ) {
    this.bookingService.currentTotalPrice.pipe(untilDestroyed(this)).subscribe((price) => {
      this.totalDiscountPrice = price;
    });
  }

  async ngOnInit() {
    await this.getDiscountReasonList();

    if (!this.isNewspaDiscountReasonList) {
      this.maxPrice_absolute = this.guestPrice;
    } else {
      this.maxPrice_absolute = this.guestPrice / 2;
    }
    this.calculateTotalPrice();
  }

  ngOnChanges() {
    if (!this.guestPrice) {
      return;
    }
    if (!this.isNewspaDiscountReasonList) {
      this.maxPrice_absolute = this.guestPrice;
      return;
    }
    this.maxPrice_absolute = this.guestPrice / 2;

    if (!this.isDifferentPayer) {
      this.guestForms.forEach((form, i) => {
        if (form.get('absolute').value > this.maxPrice_absolute) {
          form.get('absolute').setValue(this.maxPrice_absolute);
        }
        this.calculateDiscount(i);
      });
    }
  }

  isValidNumber(value: number): boolean {
    return typeof value === 'number' && !isNaN(value);
  }

  async getDiscountReasonList() {
    const list = await lastValueFrom(this.spaDiscount.getDiscountReasons().pipe(take(1), untilDestroyed(this)));
    this.spaDiscountReasonList = list;
    //NEUE VERSION ALTE VERSION RABATTFUNKTION
    if (list.elements[0].version == 2) {
      this.isNewspaDiscountReasonList = true;
    } else {
      this.isNewspaDiscountReasonList = false;
    }
  }

  onDiscountReasonChange(guest: GuestDTO, formcontrolname: string) {
    const i = this.guestSelectionPipe.transform(guest, this.selectedGuests);
    const form = this.guestForms[i];
    const reason = form.get('discountReason').value;
    const matchingReason = this.spaDiscountReasonList
      ? this.spaDiscountReasonList.elements.find((element) => element.title === reason)
      : undefined;

    if (reason == null) {
      form.get('fulldiscount').reset();
      form.get('percent').reset();
      form.get('absolute').reset();
      form.get('percent').disable();
      form.get('absolute').disable();
    }

    if (formcontrolname == 'discountReason') {
      form.get('percent').reset(undefined, { emitEvent: false });
      form.get('absolute').reset(undefined, { emitEvent: false });
      switch (true) {
        case this.hasDiscount(/-10%/, reason):
          form.get('percent').setValue(10, { emitEvent: false });
          break;
        case this.hasDiscount(/-20%/, reason):
          form.get('percent').setValue(20, { emitEvent: false });
          break;
        case this.hasDiscount(/-30%/, reason):
          form.get('percent').setValue(30, { emitEvent: false });
          break;
        case reason?.includes('TOM-Voucher'):
          form.get('absolute').setValue(20, { emitEvent: false });
          break;
      }
    }

    //NEUE VERSION ALTE VERSION RABATTFUNKTION
    if (this.isNewspaDiscountReasonList) {
      const form = this.guestForms[i];

      form.get('percent').enable();
      form.get('absolute').enable();

      if (formcontrolname == 'percent') {
        form.get('absolute').reset();
      }
      if (formcontrolname == 'absolute') {
        form.get('percent').reset();
      }
    } else {
      if (formcontrolname == 'percent') {
        form.get('absolute').reset();
        form.get('fulldiscount').reset();
      }
      if (formcontrolname == 'absolute') {
        form.get('percent').reset();
        form.get('fulldiscount').reset();
      }
      if (formcontrolname == 'fulldiscount') {
        form.get('percent').reset();
        form.get('absolute').reset();
      }

      if (formcontrolname == 'discountReason' && matchingReason) {
        if (matchingReason.hundredPercent) {
          form.get('percent').disable();
          form.get('absolute').disable();
          form.get('fulldiscount').disable();
          form.get('percent').setValue('');
          form.get('absolute').setValue('');
          form.get('fulldiscount').setValue(true);
        } else {
          form.get('percent').enable();
          form.get('absolute').enable();
          form.get('fulldiscount').enable();
          form.get('fulldiscount').setValue(false);
        }
      }
    }

    this.calculateDiscount(i);
  }

  hasDiscount(discountRegex, inputString): boolean {
    return discountRegex.test(inputString);
  }

  calculateDiscount(index: number) {
    const form = this.guestForms[index];
    const percentDiscount = form.get('percent').value;
    const absoluteDiscount = form.get('absolute').value;
    const fulldiscount = form.get('fulldiscount').value;
    const productPrice = this.guestPrice;

    let discountedPrice = productPrice;

    if (absoluteDiscount > 0) {
      form.get('absolute');
    } else {
      form.get('absolute').setValue('');
    }

    if (form.valid) {
      if (fulldiscount) {
        discountedPrice = 0;
      }
      if (percentDiscount) {
        discountedPrice = productPrice - (productPrice * percentDiscount) / 100;
      } else if (absoluteDiscount) {
        discountedPrice = productPrice - absoluteDiscount;
      }
      discountedPrice = Math.max(0, discountedPrice);
    }

    //NEUE VERSION ALTE VERSION RABATTFUNKTION
    if (this.isNewspaDiscountReasonList) {
      if (discountedPrice < productPrice / 2) {
        discountedPrice = productPrice / 2;
      }
    }

    this.updatePriceInput(index, discountedPrice);
  }

  updatePriceInput(index: number, discountedPrice: number) {
    const form = this.guestForms[index];
    if (discountedPrice !== this.guestPrice) {
      form.get('priceInput').setValue(discountedPrice.toFixed(2), { emitEvent: false });
    } else {
      form.get('priceInput').reset();
    }
    this.calculateTotalPrice();
  }

  createForms(guests: number) {
    for (let i = 0; i < guests; i++) {
      const form = this.formBuilder.group({
        discountReason: ['', Validators.required],
        percent: [{ value: undefined, disabled: true }, [Validators.min(1), Validators.max(100)]],
        absolute: [{ value: undefined, disabled: true }, Validators.min(1)],
        fulldiscount: [{ value: false, disabled: true }],
        priceInput: [''],
      });

      this.guestForms.push(form);
    }
    if (!this.isDifferentPayer) {
      this.calculateTotalPrice();
    }
  }

  calculateTotalPrice() {
    if (this.isDifferentPayer) {
      return;
    }
    const discountedPrices = this.guestForms.map((discount) => {
      let priceInput = parseFloat(discount.value.priceInput);
      const productPrice = this.guestPrice;
      if (isNaN(priceInput)) {
        priceInput = productPrice;
      }
      return priceInput;
    });

    const totalDiscountedPrice = discountedPrices.reduce((acc, price) => acc + price, 0);
    this.bookingService.changeTotalPrice(totalDiscountedPrice);

    this.updateDiscount();
  }

  updateDiscount() {
    const discountdata: SpaDiscountDTO[] = [];

    this.guestForms.map((form) => {
      const percent = form.get('percent').value;
      const absolute = form.get('absolute').value;
      const reason = form.get('discountReason').value;
      const fulldiscount = form.get('fulldiscount').value;

      let discount: SpaDiscountDTO;

      if (percent > 0) {
        discount = {
          amount: parseFloat(percent),
          unit: SpaDiscountUnitDTO.percent,
          reason: reason,
        };
      }
      if (absolute > 0) {
        discount = {
          amount: parseFloat(absolute),
          unit: SpaDiscountUnitDTO.absolute,
          reason: reason,
        };
      }
      if (fulldiscount) {
        discount = {
          amount: 100,
          unit: SpaDiscountUnitDTO.percent,
          reason: reason,
        };
      }

      discountdata.push(discount);
    });
    this.discountChanged.emit(discountdata);
  }

  isChecked({ guestId }: GuestDTO) {
    return !!_.find(this.selectedGuests, { guestId });
  }

  toggleFormVisibility(guest: GuestDTO) {
    const index = this.guestSelectionPipe.transform(guest, this.selectedGuests);
    this.showFormArray[index] = !this.showFormArray[index];

    if (this.showFormArray[index] == false) {
      this.guestForms[index].reset();
      this.calculateTotalPrice();
    }
  }

  loadGuests(searchTerm: string) {
    this.searching = true;
    this.guestsService
      .getGuests(searchTerm)
      .pipe(take(1))
      .subscribe({
        next: ({ elements }: PaginatedGuestDTO) => {
          this.guestSearchResults = _.map(elements, (guest) => {
            const checked = this.isChecked(guest);
            return {
              isChecked: checked,
              alreadySelected: checked,
              data: guest,
            };
          });
        },
        complete: () => {
          this.searching = false;
        },
      });
  }

  onSelectGuests(guests: GuestDTO[]): void {
    if (!guests) {
      console.warn('Invalid input: guests array is null');
      return;
    }
    this.selectedGuests.push(...guests);
    this.onChange(this.selectedGuests);
    this.createForms(guests.length);
  }

  onClickSelectedGuest(guest: GuestDTO): void {
    if (this.showExistingBookings) {
      this.clickOnGuest.emit(guest);
      this.existingBookingsGuestId = guest.guestId;
    }
  }

  removeSelectedGuest(index: number): void {
    this.selectedGuests.splice(index, 1);
    this.guestForms.splice(index, 1);
    this.onChange(this.selectedGuests);
    this.clickOnGuest.emit(undefined);
    this.calculateTotalPrice();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (obj: GuestDTO[]) => {
    /** do nothing */
  };

  onTouched = () => {
    /** do nothing */
  };

  registerOnChange(fn: (obj: GuestDTO[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(guests: GuestDTO[]): void {
    if (guests != null) {
      this.selectedGuests = guests;
      this.createForms(guests.length);
    }
  }
}
