// @ts-strict-ignore
import { Component, HostBinding, OnInit, ViewContainerRef } from '@angular/core';
import { forkJoin, Observable, Subscription, take } from 'rxjs';
import {
  AcAccountDTO,
  AcAccountListDTO,
  AccountsService,
  BookingsService,
  GuestDTO,
  PaginatedSpaBookingDTO,
  PermissionsDTO,
  SpaBookingDTO,
  SpaBookingOrderDTO,
  SpaDiscountDTO,
  SpaTicketOrderDTO,
  SpaUIService,
} from '@api';
import { StoreService } from '@state';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RemoveFlexibleProductsPipe } from '@pipe';
import { CurrentBooking, DateProduct, DateRange, ProductFamilyOrEventProduct } from '@model';
import { ModalService } from '@services';
import { BookingConfirmationComponent } from './booking-confirmation/booking-confirmation.component';
import {
  AutocompleteColumn,
  AutocompleteRow,
} from '../../../components/autocomplete-search/autocomplete-search.component';
import { BookingService } from '../../../services/booking.service';
import moment from 'moment';
import { momentToLocalDateTime } from '../../../utils/date-time.utils';
import { TokenService } from '@permission';

@UntilDestroy()
@Component({
  selector: 'spa-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
  providers: [RemoveFlexibleProductsPipe],
})
export class BookComponent implements OnInit {
  form = new FormGroup({
    productFamilyOrEvent: new FormControl(null, { validators: [Validators.required] }),
    dateProduct: new FormControl(null, { validators: [Validators.required] }),
    guests: new FormControl(null, { validators: [Validators.required] }),
    differentPayer: new FormControl(null, { validators: this.differentPayerValidator() }),
  });

  useDifferentPayer = false;

  isPrepaid = false;

  guestExistingBookings: GuestDTO;
  sending = false;
  searching = false;
  private subscriptionDiscounts: Subscription;

  @HostBinding('class') cls = 'spa-booking';

  currentBooking$: Observable<CurrentBooking> = this.storeService.select('currentBooking');

  guestsWithExistingBookings: string[] = [];

  previousGuests: GuestDTO[] = [];

  booking: SpaBookingDTO[] = null;
  discountData: SpaDiscountDTO[] = [];

  acAccountSearchResults: AutocompleteRow<AcAccountDTO>[] | null = null;

  autocompleteColumns: AutocompleteColumn<AcAccountDTO>[] = [
    {
      key: 'name',
    },
    {
      key: 'number',
    },
  ];

  selectedAcAccount: AcAccountDTO;

  protected readonly hasDiscountPermission = this.tokenService.hasPermission(
    PermissionsDTO.SPA_MANAGER_BOOKINGS_DISCOUNT,
  );

  constructor(
    private storeService: StoreService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private bookingsService: BookingsService,
    private uiService: SpaUIService,
    private acAccountService: AccountsService,
    private bookingdiscountService: BookingService,
    private readonly tokenService: TokenService,
  ) {
    modalService.setRootViewContainerRef(viewContainerRef);

    this.productFamilyOrEvent.valueChanges.subscribe((productFamilyOrEvent: ProductFamilyOrEventProduct) => {
      // reset selected date ranges
      this.dateProduct.setValue(null);
      this.storeService.set('currentBooking', { productFamilyOrEvent, guests: this.guests?.value });
    });

    this.dateProduct.valueChanges.subscribe((dateProduct: DateProduct) => {
      this.guestsWithExistingBookings = [];
      this.detectExistingBookings(this.guests?.value, dateProduct?.dateRanges);
    });

    this.guests.valueChanges.subscribe((guests: GuestDTO[]) => {
      this.storeService.set('currentBooking', {
        productFamilyOrEvent: this.productFamilyOrEvent?.value,
        guests: guests,
      });
      const addedGuests = guests?.filter(
        (guest) => this.previousGuests?.find((prevGuest) => prevGuest.guestId === guest.guestId) === undefined,
      );
      this.detectExistingBookings(addedGuests, this.dateProduct?.value?.dateRanges);
      this.previousGuests = this.guests?.value ? [...this.guests.value] : this.guests?.value;
    });
  }

  get productFamilyOrEvent() {
    return this.form?.get('productFamilyOrEvent');
  }

  get dateProduct() {
    return this.form?.get('dateProduct');
  }

  get guests() {
    return this.form?.get('guests');
  }

  get differentPayer() {
    return this.form?.get('differentPayer');
  }

  ngOnInit(): void {
    this.initForm();
  }

  //TODO disable ui during request and calculation?
  detectExistingBookings(guests: GuestDTO[], dateRanges: DateRange[]): void {
    if (dateRanges && dateRanges.length > 0 && guests && guests.length > 0) {
      const observables: Observable<PaginatedSpaBookingDTO>[] = [];
      let startDate = dateRanges[0].from;
      dateRanges?.forEach((dateRange) => {
        if (dateRange.from < startDate) {
          startDate = dateRange.from;
        }
      });
      let endDate = dateRanges[0].to;
      dateRanges?.forEach((dateRange) => {
        if (dateRange.to > endDate) {
          endDate = dateRange.to;
        }
      });
      guests.forEach((guest: GuestDTO) => {
        observables.push(
          this.bookingsService.getBookings(
            guest.guestId,
            momentToLocalDateTime(startDate),
            momentToLocalDateTime(endDate),
          ),
        );
      });

      forkJoin(observables).subscribe((paginatedGuestsBookings: PaginatedSpaBookingDTO[]) => {
        paginatedGuestsBookings.forEach((bookings) => {
          bookings.elements.some((booking) => {
            return booking.tickets.some((ticket) => {
              return dateRanges?.some((dateRange) => {
                if (
                  dateRange.to >= moment(ticket.fromDate) &&
                  dateRange.from <= moment(ticket.toDate) &&
                  !this.guestsWithExistingBookings.find((guestId) => guestId === booking.guestId)
                ) {
                  this.guestsWithExistingBookings.push(booking.guestId);
                  return true;
                } else {
                  return false;
                }
              });
            });
          });
        });
      });
    }
  }

  loadAcAccounts(searchTerm: string) {
    this.searching = true;
    this.acAccountService
      .getAcAccounts()
      .pipe(take(1))
      .subscribe({
        next: (acAccountList: AcAccountListDTO) => {
          this.acAccountSearchResults = acAccountList.elements
            .filter((acAccount) => {
              return (
                acAccount.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                acAccount.number.toLowerCase().includes(searchTerm.toLowerCase())
              );
            })
            .map((acAccount) => {
              return {
                isChecked: false,
                alreadySelected: false,
                data: acAccount,
              };
            });
        },
        complete: () => {
          this.searching = false;
        },
      });
  }

  onSelectAcAccount(acAccount: AcAccountDTO[]): void {
    this.selectedAcAccount = acAccount[0];
  }
  onRemoveAcAccount() {
    this.selectedAcAccount = null;
  }

  initForm() {
    this.currentBooking$.pipe(take(1)).subscribe((booking: CurrentBooking) => {
      this.form.patchValue({
        guests: booking?.guests,
        productFamilyOrEvent: booking?.productFamilyOrEvent,
      });
    });
  }

  clickOnGuest(guest: GuestDTO): void {
    this.guestExistingBookings = guest;
  }

  toggleUseDifferentPayer() {
    this.form.patchValue({ differentPayer: [] });
  }

  closeExistingBookings() {
    this.guestExistingBookings = null;
  }

  private differentPayerValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value?.length && this.useDifferentPayer) {
        return { payer: true };
      }

      return null;
    };
  }
  updateDiscount(discountdata: SpaDiscountDTO[]) {
    this.discountData = discountdata;
  }

  sendForm() {
    this.sending = true;
    const bookings: SpaBookingOrderDTO[] = this.guests.value.map((guest: GuestDTO, i: number): SpaBookingOrderDTO => {
      const dateProduct: DateProduct = this.dateProduct.value;
      let payer: GuestDTO;
      if (this.differentPayer.value != null && this.differentPayer.value?.length > 0) {
        payer = this.differentPayer.value[0];
      } else {
        payer = guest;
      }
      const acAccount = this.selectedAcAccount?.number;

      return {
        payerId: payer.guestId,
        guestId: guest.guestId,
        productId: dateProduct.product.productId,
        eventId: this.productFamilyOrEvent?.value?.eventProduct?.eventId,
        tickets: dateProduct.dateRanges.map((dateRange: DateRange) => {
          return {
            fromDate: momentToLocalDateTime(dateRange.from),
            toDate: momentToLocalDateTime(dateRange.to),
            resourceIds: dateProduct.product.needs,
          } as SpaTicketOrderDTO;
        }),
        discount: this.discountData ? this.discountData[i] : undefined,
        acAccount: acAccount,
      };
    });
    this.bookingsService
      .createBookings({ bookings })
      .pipe(take(1))
      .subscribe({
        next: ({ bookingResults }) => {
          this.modalService
            .create({
              comp: BookingConfirmationComponent,
              data: {
                bookingResults: bookingResults,
                guests: this.guests.value,
                bookingRequest: bookings,
                differentPayer: this.useDifferentPayer ? this.differentPayer.value : null,
              },
            })
            .onClose.subscribe(() => {
              this.guests.setValue([]);
              this.differentPayer.setValue(null);
              this.dateProduct.setValue(null);
              this.productFamilyOrEvent.setValue(null);
              this.useDifferentPayer = false;
              this.isPrepaid = false;
              this.guestExistingBookings = null;
              this.fetchEventsAndProducts();
              this.bookingdiscountService.changeTotalPrice(null);
            });
        },
        error: (err) => {
          if (err.status === 400) {
            this.sending = false;
          }
        },
        complete: () => {
          this.sending = false;
        },
      });
  }

  fetchEventsAndProducts(): void {
    this.uiService
      .getProductsOverview()
      .pipe(take(1))
      .subscribe((resp) => {
        this.storeService.set('productsByFamily', resp.productsByFamily);
        this.storeService.set('productsByEvent', resp.productsByEvent);
      });
  }
}
