<spa-page title="{{ 'label.nav.book' | transloco }}">
  <div class="spa-book-container">
    <div [formGroup]="form" class="spa-book-container__form">
      <!-- Product selection -->
      <div class="spa-section" data-testid="ticket_section">
        <div class="spa-section__label">
          {{ "subtitle.booking.ticket" | transloco }}
        </div>
        <div class="spa-section__body">
          <spa-product-selection class="mb-5" formControlName="productFamilyOrEvent"></spa-product-selection>
        </div>
      </div>

      <!-- Date/time selection -->
      <div class="spa-section" data-testid="time_section">
        <div class="spa-section__label">
          {{ "subtitle.booking.time" | transloco }}
        </div>
        <div class="spa-section__body">
          <spa-time-selection
            [productFamilyOrEventProduct]="productFamilyOrEvent.value"
            class="mb-5"
            formControlName="dateProduct"
          ></spa-time-selection>
          <spa-load-view class="mt-2"></spa-load-view>
        </div>
      </div>
      <!-- Guest selection -->
      <div class="spa-section" data-testid="guest_section">
        <div class="spa-section__label">
          {{ "subtitle.booking.guest" | transloco }}
        </div>
        <div class="spa-section__body">
          <spa-guest-selection
            formControlName="guests"
            (clickOnGuest)="clickOnGuest($event)"
            (discountChanged)="updateDiscount($event)"
            [guestPrice]="this.dateProduct.value?.product?.price"
            [showDiscountForm]="hasDiscountPermission"
            [totalPrice]="this.dateProduct.value?.product?.price | calculateTotalPricePipe: guests.value?.length"
            [existingBookingsGuestId]="guestExistingBookings?.guestId"
            [showExistingBookings]="true"
            [guestsWithExistingBookings]="guestsWithExistingBookings"
            checkboxSelectionType="multiple"
            data-testid="guest_selection_guest"
          ></spa-guest-selection>
          <dx-checkbox
            [(ngModel)]="useDifferentPayer"
            (ngModelChange)="toggleUseDifferentPayer()"
            [ngModelOptions]="{ standalone: true }"
            [disabled]="isPrepaid"
            class="mt-3"
            id="payer-checkbox"
            label="{{ 'checkbox.booking.different-payer' | transloco }}"
            data-testid="different_payer_checkbox"
          ></dx-checkbox>
        </div>
      </div>

      <ng-container *ngIf="guestExistingBookings">
        <spa-existing-bookings
          [guestExistingBookings]="guestExistingBookings"
          (closeExistingBookings)="closeExistingBookings()"
          data-testid="guests_existing_bookings_overview"
        ></spa-existing-bookings>
      </ng-container>

      <!-- Payer selection -->
      <div *ngIf="useDifferentPayer" class="spa-section" data-testid="different_payer_section">
        <div class="spa-section__label">
          {{ "subtitle.booking.payer" | transloco }}
        </div>
        <div class="spa-section__body">
          <spa-guest-selection
            [selectMultipleGuests]="false"
            [isDifferentPayer]="true"
            checkboxSelectionType="single"
            formControlName="differentPayer"
            id="differentPayerForm"
            class="differentPayerForm"
            [guestPrice]="this.dateProduct.value?.product?.price | calculateTotalPricePipe: guests.value?.length"
            data-testid="guest_selection_payer"
          ></spa-guest-selection>
        </div>
      </div>

      <ng-container *hasPermission="'spa.manager.bookings.ac'">
        <div class="spa-section" role="prepaidCheckbox">
          <div class="spa-section__label"></div>
          <div class="spa-section__body">
            <dx-checkbox
              [disabled]="useDifferentPayer"
              [(ngModel)]="isPrepaid"
              [ngModelOptions]="{ standalone: true }"
              class="mt-3"
              id="ac-checkbox"
              label="{{ 'label.acaccount' | transloco }}"
              data-testid="ac_account_checkbox"
            ></dx-checkbox>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isPrepaid">
        <div class="spa-section" data-testid="ac_account_section">
          <div class="spa-section__label">
            {{ "label.acaccount" | transloco }}
          </div>

          <div class="spa-section__body">
            <div class="spa-section-acaccount" *ngIf="selectedAcAccount">
              <div class="spa-section-acaccount-element">
                <spa-button (click)="onRemoveAcAccount()" shape="link" asset="close"></spa-button>
              </div>

              <div class="spa-section-acaccount-element">
                <dx-text class="label-text style-infotext-small">Name</dx-text>
                <dx-text class="style-paragraph-standard">{{ selectedAcAccount.name }}</dx-text>
              </div>

              <div class="spa-section-acaccount-element">
                <dx-text class="label-text style-infotext-small">number</dx-text>
                <dx-text class="style-paragraph-standard">{{ selectedAcAccount.number }}</dx-text>
              </div>
            </div>

            <div class="spa-autocomplete-search-container">
              <spa-autocomplete-search
                class="spa-search"
                [rows]="acAccountSearchResults"
                [searching]="searching"
                [columns]="autocompleteColumns"
                (fetchData)="loadAcAccounts($event)"
                (selectRow)="onSelectAcAccount($event)"
                data-testid="ac_account_search"
              >
                <spa-autocomplete-template columnKey="name"> </spa-autocomplete-template>
              </spa-autocomplete-search>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Confirm booking button -->
      <div class="spa-section spa-booking-confirm-button-container">
        <div class="spa-section__label"></div>
        <div class="spa-section__body">
          <spa-button
            (clickedButton)="sendForm()"
            [disabled]="form.invalid || sending"
            class="spa-booking-confirm-button"
            label="{{ 'button.booking.confirm' | transloco }}"
            data-testid="confirm_booking_button"
          ></spa-button>
        </div>
      </div>
    </div>
  </div>
</spa-page>
